import React from "react";

import { Helmet } from "react-helmet";

import Header from "../components/header";
import Footer from "../components/footer";
import "./home.css";

import { useParallax } from "react-scroll-parallax";
import { Parallax } from "react-scroll-parallax";
import { Link, animateScroll as scroll } from "react-scroll";
import Particles, { ISourceOptions } from "react-tsparticles";

const Home = (props) => {
  const { ref } = useParallax({ speed: 10 });

  const somosParallax = () => {
    return (
      <section id="somos">
        <div className="home-somos section" ref={ref}>
          <div className="home-container07">
            <h3 className="home-text07">Tu Tarjeta Gamer PrePago</h3>
            <br></br>
            <Parallax translateX={["0px", "10px"]} translateY={[0, -20]}>
              <img
                alt="image"
                src="/playground_assets/prepago2-400w.png"
                image_src="dab1286f-0104-48fa-bd7d-135e132129dd"
                className="home-image07"
              />
            </Parallax>
          </div>
          <div className="home-container08">
            <Parallax translateY={[-10, 0]} translateX={["-10px", "0px"]}>
              <span className="home-text08">
                LukasCard es una excelente opción para los gamers que buscan una
                tarjeta prepago digital segura, confiable y conveniente para
                realizar compras en línea y aprovechar beneficios exclusivos
              </span>
            </Parallax>
          </div>
          <img
            alt="image"
            src="/playground_assets/turquoise-circle.svg"
            className="home-turquoise-cirble1"
          />
          <img
            alt="image"
            src="/playground_assets/purple-circle.svg"
            className="home-purple-circle1"
          />
        </div>
      </section>
    );
  };

  return (
    <div className="home-container">
      <div class="background">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <Helmet>
        <title>LukasCards</title>
        <meta property="og:title" content="LukasCards" />
      </Helmet>
      <div data-role="Header" className="home-header">
        <Header></Header>
        <div data-role="MobileMenu" className="home-mobile-menu">
          <div className="home-top">
            <div className="home-logo">
              <img
                alt="image"
                src="/playground_assets/lucascardlogo-200h.png"
                className="home-image"
              />
            </div>
            <div data-role="CloseMobileMenu" className="home-close-menu">
              <svg viewBox="0 0 1024 1024" className="home-icon">
                <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
              </svg>
            </div>
          </div>
          <div className="home-mid">
            <Link
              to="somos"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="spacemenu"
            >
              <span className="home-text">Quienes Somos</span>
            </Link>
            <Link
              to="rewards"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="spacemenu"
            >
              <span className="home-text">Rewards</span>
            </Link>
            <Link
              to="cash"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="spacemenu"
            >
              <span className="home-text">Cash</span>
            </Link>
            <Link
              to="exchange"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="spacemenu"
            >
              <span className="home-text">Exchange</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="home-slider">
        <div className="home-blur-background"></div>
        <div className="home-hero">
          <div className="home-container06">
            <h1 className="home-text06">
              La tarjeta prepago que te lleva al siguiente nivel
            </h1>
          </div>
          <Parallax translateY={[0, -5]} translateX={["0px", "-10px"]}>
            <img
              alt="image"
              src="/playground_assets/home2-400w.png"
              className="home-image01"
            />
          </Parallax>
        </div>
        <Parallax translateY={[0, 800]} translateX={["0px", "-800px"]}>
          <img
            alt="image"
            src="/playground_assets/turquoise-circle.svg"
            className="home-turquoise-cirble"
          />
        </Parallax>

        <Parallax translateY={[0, 100]} translateX={["-100px", "100px"]}>
          <img
            alt="image"
            src="/playground_assets/purple-circle.svg"
            className="home-purple-circle"
          />
        </Parallax>

        <Parallax translateX={["-100px", "100px"]} translateY={[-100, 100]}>
          <img
            alt="image"
            src="/playground_assets/left.svg"
            className="home-left"
          />
        </Parallax>
        <Parallax translateX={["-100px", "100px"]} translateY={[-100, 100]}>
          <img
            alt="image"
            src="/playground_assets/right.svg"
            className="home-right"
          />
        </Parallax>
      </div>
      <div className="home-aliados ">
        <div className="home-divider"></div>
        <img
          alt="image"
          src="/playground_assets/microsoft_azure_logo.svg-200h.png"
          className="home-image02"
        />
        <img
          alt="image"
          src="/playground_assets/visualhunter-1686f70156-200h.png"
          className="home-image03"
        />
        <img
          alt="image"
          src="/playground_assets/pci-dss-compliant-600x600.svg"
          className="home-image04"
        />
        <img
          alt="image"
          src="/playground_assets/logo-5.svg"
          className="home-image05"
        />
        <img
          alt="image"
          src="/playground_assets/logo-4.svg"
          className="home-image06"
        />
        <div className="home-divider1"></div>
      </div>
      {somosParallax()}
      <div className="home-rewards section" id="rewards">
        <div className="home-container09">
          <h3 className="home-text09">Lukas Rewards</h3>
          <div className="home-container10">
            <span className="home-text10">
              <Parallax translateY={[-0, 5]} translateX={["0px", "-10px"]}>
                Gana más puntos al comprar juegos y suscripciones con LukasCard.
                Obtén hasta 5x puntos en compras de entretenimiento, 3x en la
                tienda de Lukas y 1x en otras compras. Canjea puntos por
                descuentos y recompensas.
              </Parallax>
            </span>
          </div>
        </div>
        <div className="home-container11">
          <Parallax translateX={["0px", "10px"]} translateY={[0, 20]}>
            <img
              alt="image"
              src="/playground_assets/puntos-400w.png"
              image_src="dab1286f-0104-48fa-bd7d-135e132129dd"
              className="home-image08"
            />
          </Parallax>
        </div>
        <img
          alt="image"
          src="/playground_assets/turquoise-circle.svg"
          className="home-turquoise-cirble2"
        />
      </div>
      <div className="home-cash section" id="cash">
        <div className="home-container12">
          <h3 className="home-text11">Lukas Cash</h3>
          <Parallax translateX={["0px", "10px"]} translateY={[0, 10]}>
            <img
              alt="image"
              src="/playground_assets/cash-400w.png"
              image_src="dab1286f-0104-48fa-bd7d-135e132129dd"
              className="home-image09"
            />
          </Parallax>
        </div>
        <div className="home-container13">
          <span className="home-text12">
            <Parallax translateY={[0, 20]} translateX={["0px", "10px"]}>
              LukasCard es una forma segura y conveniente de hacer pagos en
              línea y en tiendas físicas. Simplemente elige la opción de
              &quot;tarjeta de prepago&quot; o &quot;tarjeta de débito&quot; en
              el sitio web, introduce los detalles de tu tarjeta LukasCard,
              confirma la operación y verifica el saldo en la aplicación.
              LukasCard es ampliamente aceptada en diferentes comercios en todo
              el mundo.
            </Parallax>
          </span>
        </div>
        <Parallax translateX={["0px", "100px"]} translateY={[0, 100]}>
          <img
            alt="image"
            src="/playground_assets/turquoise-circle.svg"
            className="home-turquoise-cirble3"
          />
        </Parallax>
        <Parallax translateX={["-100px", "100px"]} translateY={[-100, 100]}>
          <img
            alt="image"
            src="/playground_assets/purple-circle.svg"
            className="home-purple-circle2"
          />
        </Parallax>
      </div>
      <div className="home-cryptos section" id="exchange">
        <div className="home-container14">
          <h3 className="home-text13">Lukas Exchange</h3>
          <div className="home-container15">
            <span className="home-text14">
              <Parallax translateY={[0, 20]} translateX={["0px", "10px"]}>
                Permite a los usuarios comprar y vender diferentes
                criptomonedas, incluyendo Ethereum y otras, así como el
                stablecoin USDS. La plataforma de intercambio es fácil de usar y
                segura, con gráficos y análisis de precios disponibles para
                ayudar a los usuarios a tomar decisiones informadas de compra y
                venta. LukasCrypto Exchange permite a los usuarios intercambiar
                criptomonedas y USDS desde la comodidad de su aplicación
                LukasCard, eliminando la necesidad de salir a otras plataformas
                de intercambio.
              </Parallax>
            </span>
          </div>
        </div>
        <div className="home-container16">
          <Parallax translateX={["0px", "10px"]} translateY={[0, 20]}>
            <img
              alt="image"
              src="/playground_assets/crypto-400w.png"
              image_src="dab1286f-0104-48fa-bd7d-135e132129dd"
              className="home-image10"
            />
          </Parallax>
        </div>
        <Parallax translateY={[0, 100]} translateX={["-100px", "100px"]}>
          <img
            alt="image"
            src="/playground_assets/purple-circle.svg"
            className="home-purple-circle3"
          />
        </Parallax>
      </div>
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  );
};

export default Home;
