import React from "react";

import PropTypes from "prop-types";

import StoreBanner from "./store-banner";
import "./header.css";
import { Link, animateScroll as scroll } from "react-scroll";

const Header = (props) => {
  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
   
      <header className="header-header">
        <div className="header-logo">
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="header-image"
            onClick={scrollToTop}
          />
        </div>
        <div className="header-menu">
          <Link
            activeClass="active"
            to="somos"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            <span className="header-text">{props.text}</span>
          </Link>
          <Link
            activeClass="active"
            to="rewards"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
          <span className="header-text1">{props.text1}</span>
          </Link>
          <Link
            activeClass="active"
            to="cash"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
          <span className="header-text2">{props.text2}</span>
          </Link>
          <Link
            activeClass="active"
            to="exchange"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
          <span className="header-text3">{props.text3}</span>
          </Link>

          <span className="header-text3">Blog</span>
          <span className="header-text3">Soporte</span>
        
        </div>
        <div className="header-container">
          <StoreBanner></StoreBanner>
        </div>
        <div data-role="BurgerMenu" className="header-burger-menu">
          <svg viewBox="0 0 1024 1024" className="header-menu1">
            <path d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            <path d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            <path d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
          </svg>
        </div>
      </header>

  );
};

Header.defaultProps = {
  text4: "Trabaja con nosotros",
  text5: "Blog",
  image_alt: "image",
  text3: "Exchange",
  text: "Tarjeta Prepago",
  text2: "Cash",
  text1: "Rewards",
  image_src: "/playground_assets/lucascardlogo-200h.png",
};

Header.propTypes = {
  text4: PropTypes.string,
  text5: PropTypes.string,
  image_alt: PropTypes.string,
  text3: PropTypes.string,
  text: PropTypes.string,
  text2: PropTypes.string,
  text1: PropTypes.string,
  image_src: PropTypes.string,
};

export default Header;
